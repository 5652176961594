import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import BeatLoader from 'react-spinners/BeatLoader';

import headerBg from 'images/element/contact/header-bg.png';
import headerText from 'images/element/contact/header-text.svg';
import contact from 'data/contact.json';

import Layout from 'components/Layout';
import SEO from 'components/Seo';
import Dimension from 'components/dimension';
import {
  TextInputField,
  TextAreaField,
  EmailInputField,
} from 'components/shared/form';
import { getFormApiURL } from 'components/shared/apiURL';

const ComponentText = {
  NAME_LABEL: 'Name',
  NAME_PLACEHOLDER: 'Your Name',
  EMAIL_LABEL: 'Email',
  EMAIL_PLACEHOLDER: 'Your Email',
  SUBJECT_LABEL: 'Subject',
  SUBJECT_PLACEHOLDER: 'Subject',
  MESSAGE_LABEL: 'Message',
  MESSAGE_PLACEHOLDER: 'Your Message',
  REQUIRED_VALIDATION: 'ต้องระบุข้อมูล',
  SUBMIT_BUTTON: 'Send',
  TITLE: 'Talk to us!',
  REQUEST_SUCCESS: 'MESSAGE SENT',
  REQUEST_FAILURE: 'MESSAGE SEND FAILURE',
};

const contactCards = [
  {
    id: 'email',
    title: 'Email',
    info: contact.email,
    iconName: ['fas', 'envelope'],
  },
  {
    id: 'phone',
    title: 'Phone',
    info: contact.phone,
    iconName: ['fas', 'phone-alt'],
  },
  {
    id: 'address',
    title: 'Visit us',
    info: contact.address1,
    subInfo: contact.address2,
    iconName: ['fas', 'map-marked-alt'],
  },
];

const initialValues = {
  name: '',
  email: '',
  subject: '',
  staff: '',
  message: '',
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required(ComponentText.REQUIRED_VALIDATION),
  email: Yup.string()
    .email()
    .required(ComponentText.REQUIRED_VALIDATION),
  subject: Yup.string().required(ComponentText.REQUIRED_VALIDATION),
  message: Yup.string().required(ComponentText.REQUIRED_VALIDATION),
});

const formURL = getFormApiURL();

const reducer = (state, action) => {
  switch (action.type) {
    case 'init': {
      return {
        ...state,
        status: 'idle',
      };
    }
    case 'request': {
      return {
        ...state,
        status: 'pending',
      };
    }
    case 'success': {
      return {
        ...state,
        status: 'resolved',
      };
    }
    case 'error': {
      return {
        ...state,
        status: 'rejected',
        error: action.error,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const Contact = () => {
  const [state, transit] = React.useReducer(reducer, {
    status: 'idle',
    error: null,
  });

  const handleSubmit = async values => {
    transit({ type: 'request' });
    try {
      const response = await axios.post(formURL, {
        ...values,
        type: 'contact',
      });
      if (response && response.status === 200) {
        transit({ type: 'success' });
      } else {
        console.error(`Error: Send mail error`);
        transit({ type: 'error' });
      }
    } catch (error) {
      console.error(`Error`, error);
      transit({ type: 'error' });
    }
  };

  const form = (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {formikBag => {
        const isRequestPending = state.status === 'pending';
        const disabledSubmit =
          !formikBag.dirty || !formikBag.isValid || isRequestPending;
        // console.log(`Value`, formikBag.values);
        return (
          <form
            autoComplete="off"
            onSubmit={formikBag.handleSubmit}
            className="w-full"
          >
            <div className="flex flex-col">
              <div className="w-full px-10 py-4 grid grid-cols-1 lg:grid-cols-2 gap-2">
                <div className="w-full mb-2 text-xl font-bold text-white lg:text-2xl lg:col-span-2">
                  {ComponentText.TITLE}
                </div>
                <TextInputField
                  required
                  id="name"
                  name="name"
                  placeholder={ComponentText.NAME_PLACEHOLDER}
                  disabled={isRequestPending}
                />
                <EmailInputField
                  required
                  id="email"
                  name="email"
                  placeholder={ComponentText.EMAIL_PLACEHOLDER}
                  disabled={isRequestPending}
                />
                <div className="lg:col-span-2">
                  <TextInputField
                    required
                    id="subject"
                    name="subject"
                    placeholder={ComponentText.SUBJECT_PLACEHOLDER}
                    disabled={isRequestPending}
                  />
                </div>
                <div className="lg:col-span-2">
                  <TextAreaField
                    required
                    id="message"
                    name="message"
                    placeholder={ComponentText.MESSAGE_PLACEHOLDER}
                    disabled={isRequestPending}
                  />
                </div>
              </div>
              <div className="flex justify-end px-10">
                <button
                  type="submit"
                  disabled={disabledSubmit}
                  className={
                    `${disabledSubmit ? 'cursor-not-allowed' : 'cursor-auto'}` +
                    ' w-40 py-2 bg-teal-700 border-gray-200 border text-gray-200 font-bold' +
                    ' hover:bg-teal-800'
                  }
                >
                  <div className="flex items-center justify-center">
                    {isRequestPending ? (
                      <BeatLoader
                        loading
                        color="#dfdfdf"
                        size={12}
                        margin={0}
                      />
                    ) : (
                      ComponentText.SUBMIT_BUTTON
                    )}
                  </div>
                </button>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );

  return (
    <Layout
      renderContent={() => {
        return (
          <>
            <SEO title="Contact" />
            <div
              className="flex flex-col items-center w-full bg-white max-w-screen-xl bg-main"
              style={{
                marginTop: Dimension.NAVBAR_HEIGHT,
              }}
            >
              <div className="relative">
                <img
                  src={headerBg}
                  alt="header background"
                  className="object-cover w-full h-auto"
                />
                <div className="absolute top-0 left-0 w-full h-full">
                  <div className="flex items-center justify-center w-full h-full">
                    <img
                      src={headerText}
                      alt="Meet our expert team"
                      className="w-full h-auto"
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col items-center justify-center w-full py-8 mt-4 md:mt-0 max-w-screen-md">
                {state.status === 'resolved' ? (
                  <div className="flex flex-col items-center py-16">
                    <FontAwesomeIcon
                      className="text-5xl text-teal-200"
                      icon={['fas', 'check-circle']}
                    />
                    <div className="mt-3 text-xl text-teal-200">
                      {ComponentText.REQUEST_SUCCESS}
                    </div>
                  </div>
                ) : (
                  form
                )}
                {state.status === 'rejected' ? (
                  <div className="flex justify-end w-full px-10 mt-3">
                    <FontAwesomeIcon
                      className="text-xl text-red-300"
                      icon={['fas', 'exclamation-circle']}
                    />
                    <div className="ml-3 text-base text-red-300">
                      {ComponentText.REQUEST_FAILURE}
                    </div>
                  </div>
                ) : null}
              </div>

              <div
                className="flex flex-col items-center w-full py-32 bg-no-repeat bg-cover"
                style={{ backgroundImage: `url(${headerBg})` }}
              >
                <div className="w-full grid grid-cols-1 gap-24 lg:grid-cols-3 lg:gap-4 max-w-screen-lg">
                  {contactCards.map(card => {
                    return (
                      <div
                        key={card.id}
                        className="flex items-center justify-center"
                      >
                        <div
                          className="relative flex flex-col rounded-sm bg-main"
                          style={{ width: '280px', height: '180px' }}
                        >
                          <div
                            className="absolute flex items-center justify-center w-full"
                            style={{ top: '-32px', left: 'auto' }}
                          >
                            <div
                              className="flex items-center justify-center rounded-full bg-secondary"
                              style={{ width: 64, height: 64 }}
                            >
                              <FontAwesomeIcon
                                className="text-lg text-white fab fa-facebook leading-lg"
                                icon={card.iconName}
                              />
                            </div>
                          </div>

                          <div className="flex items-end justify-center h-20 font-semibold text-white">
                            {card.title}
                          </div>
                          <div className="mt-2 flex items-center justify-center text-white">
                            {card.info}
                          </div>
                          {card.subInfo ? (
                            <div className="flex items-center justify-center text-white">
                              {card.subInfo}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        );
      }}
    />
  );
};
export default Contact;
